import { Avatar, MenuItem, Select } from '@material-ui/core';
import PropTypes from 'prop-types';
import { getCountryCallingCode } from 'react-phone-number-input';
// import en from 'react-phone-number-input/locale/en.json';
import React, { useState } from 'react';
// import { FlagSharp } from '@mui/icons-material';

const countries = {
  HU: {
    label: 'Magyarország',
    icon: '/static/flags/hu.svg'
  },
  AT: {
    label: 'Ausztria',
    icon: '/static/flags/at.svg'
  },
  RO: {
    label: 'Románia',
    icon: '/static/flags/ro.svg'
  },
  SK: {
    label: 'Szlovákia',
    icon: '/static/flags/sk.svg'
  },
  HR: {
    label: 'Horvátország',
    icon: '/static/flags/hr.svg'
  },
  GB: {
    label: 'Nagy-Britannia',
    icon: '/static/flags/gb.svg'
  },
  DE: {
    label: 'Németország',
    icon: '/static/flags/de.svg'
  },
  RU: {
    label: 'Oroszország',
    icon: '/static/flags/ru.svg'
  },
  FR: {
    label: 'Franciaország',
    icon: '/static/flags/fr.svg'
  },
  CZ: {
    label: 'Csehország',
    icon: '/static/flags/cz.svg'
  },
  IT: {
    label: 'Olaszország',
    icon: '/static/flags/it.svg'
  },
  CH: {
    label: 'Svájc',
    icon: '/static/flags/ch.svg'
  },
  ES: {
    label: 'Spanyolország',
    icon: '/static/flags/es.svg'
  },
  US: {
    label: 'Amerikai Egyesült Államok',
    icon: '/static/flags/us.svg'
  },
  NL: {
    label: 'Hollandia',
    icon: '/static/flags/nl.svg'
  },
  UA: {
    label: 'Ukrajna',
    icon: '/static/flags/ua.svg'
  },
  BE: {
    label: 'Belgium',
    icon: '/static/flags/be.svg'
  },
  FI: {
    label: 'Finnország',
    icon: '/static/flags/fi.svg'
  },
  SI: {
    label: 'Szlovénia',
    icon: '/static/flags/si.svg'
  },
  RS: {
    label: 'Szerbia',
    icon: '/static/flags/rs.svg'
  },
  PL: {
    label: 'Lengyelország',
    icon: '/static/flags/pl.svg'
  },
  SE: {
    label: 'Svédország',
    icon: '/static/flags/se.svg'
  },
  DK: {
    label: 'Dánia',
    icon: '/static/flags/dk.svg'
  },
  NO: {
    label: 'Norvégia',
    icon: '/static/flags/no.svg'
  },
  IE: {
    label: 'Írország',
    icon: '/static/flags/ie.svg'
  },
  TR: {
    label: 'Törökország',
    icon: '/static/flags/tr.svg'
  },
  MT: {
    label: 'Málta',
    icon: '/static/flags/mt.svg'
  },
  PT: {
    label: 'Portugália',
    icon: '/static/flags/pt.svg'
  },
  QA: {
    label: 'Qatar',
    icon: '/static/flags/qa.svg'
  },
  CA: {
    label: 'Kanada',
    icon: '/static/flags/ca.svg'
  },
  LI: {
    label: 'Liechtenstein',
    icon: '/static/flags/li.svg'
  },
  LU: {
    label: 'Luxemburg',
    icon: '/static/flags/lu.svg'
  },
  LV: {
    label: 'Lettország',
    icon: '/static/flags/lv.svg'
  },
  LT: {
    label: 'Litvánia',
    icon: '/static/flags/lt.svg'
  },
  GR: {
    label: 'Görögország',
    icon: '/static/flags/gr.svg'
  },
};

const CustomCountrySelect = ({ value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  // it doesn't show the select component, it shows the avatar, and on click
  // it opens the dropDown with the menu items,
  // however select is needed bacause of the onChange and value props, therefore it is hidden
  return (
    <>
      <Avatar
        variant="square"
        sx={{
          width: '20px',
          height: '15px',
          marginTop: '2px',
          borderRadius: '1.5px',
          '&:hover': {
            transition: 'box-shadow 300ms',
            cursor: 'pointer',
            boxShadow: '0 0 20px 0 rgba(33, 33, 33, 0.6)',
          }
        }}
        onClick={() => setIsOpen(true)}
        alt={value}
        src={countries[value]?.icon}
      />
      <Select
        value={value}
        onChange={(event) => onChange(event.target.value)}
        variant="outlined"
        open={isOpen}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        sx={{ marginRight: '10px', maxWidth: '0px', maxHeight: '0px', visibility: 'hidden' }}
      >
        {Object.entries(countries).map(([country]) => (
          <MenuItem
            divider
            key={country}
            value={country}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <>
              <Avatar
                variant="square"
                sx={{ width: '20px', height: '15px' }}
                alt={country}
                src={countries[country].icon}
              />
              &nbsp;
              &nbsp;
              {countries[country].label}
              {` +${getCountryCallingCode(country)}`}
            </>
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

CustomCountrySelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default CustomCountrySelect;
